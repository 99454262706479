<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(quizUpdate)">
      <v-card>
        <v-card-title>
          Quiz update
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <ValidationProvider
                  v-slot="{ errors }"
                  name="title"
                  rules="required"
                >
                  <v-text-field
                    v-model="quiz.title"
                    :error-messages="errors"
                    label="Title"
                    type="text"
                    required
                  />
                </ValidationProvider>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <fieldset class="pa-4">
                  <legend>Introduction:</legend>
                  <h3>
                    image
                  </h3>
                  <img
                    v-if="quiz.header_new_image"
                    :src="$displayImage(quiz.header_new_image)"
                    style="width: 300px"
                  >
                  <img
                    v-if="quiz.header_image && !quiz.header_new_image"
                    :src="$getFullPath(quiz.header_image)"
                    style="width: 300px"
                  >
                  <v-file-input
                    v-model="quiz.header_new_image"
                    accept="image/*"
                    label="Image"
                  />
                  <v-text-field
                    v-model="quiz.header_title"
                    label="Title"
                    type="text"
                  />
                  <h3 class="my-3">
                    Text
                  </h3>
                  <editor
                    v-model="quiz.intro"
                  />
                </fieldset>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <fieldset class="pa-4">
                  <legend>Conclusion:</legend>
                  <h3>
                    Image
                  </h3>
                  <img
                    v-if="quiz.conclude_new_image"
                    :src="$displayImage(quiz.conclude_new_image)"
                    style="width: 300px"
                  >
                  <img
                    v-if="quiz.conclude_image && !quiz.conclude_new_image"
                    :src="$getFullPath(quiz.conclude_image)"
                    style="width: 300px"
                  >
                  <v-file-input
                    v-model="quiz.conclude_new_image"
                    accept="image/*"
                    label="Image"
                  />
                  <v-text-field
                    v-model="quiz.conclude_title"
                    label="Title"
                    type="text"
                  />
                  <h3 class="my-3">
                    Text
                  </h3>
                  <editor
                    v-model="quiz.conclude"
                  />
                </fieldset>
              </v-col>
              <v-col
                cols="12"
              >
                <fieldset class="pa-4">
                  <legend>Footer:</legend>
                  <h3 class="my-3">
                    Footer
                  </h3>
                  <editor
                    v-model="quiz.end_footer"
                  />
                </fieldset>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <BtnToggle
                  v-model="quiz.status"
                  :positive="{text: 'Active'}"
                  :negative="{text: 'Inactive'}"
                />
                <h3 class="my-3">
                  Price range
                </h3>
                <params
                  v-if="quiz.params"
                  v-model="quiz.params"
                  :params="quiz.params"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="mt-4">
        <v-card-title>
          System Params for Tango Card
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <h3>
                  Send to RaaS Automatically
                </h3>
                <BtnToggle
                  v-model="quiz.reward_auto"
                  :positive="{text: 'Enabled'}"
                  :negative="{text: 'Disabled'}"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quiz.reward_subject"
                  label="Subject"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quiz.reward_message"
                  label="Message"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quiz.reward_UTID"
                  label="UTID"
                  type="text"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="quiz.reward_ETID"
                  label="ETID"
                  type="text"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import BtnToggle from '@/components/BtnToggle'
import authHeader from '@/utils'
import editor from '@/components/Editor'
import Params from '@/components/quiz/Params'

export default {
  name: 'QuizUpdate',
  components: { BtnToggle, editor, Params },
  breadcrumb () {
    return {
      label: this.$t('action.edit') + ' ' + this.quiz.title
    }
  },
  data: () => ({
    dialog: true,
    loading: false,
    quiz: {
      status: true,
      reward_auto: true
    }
  }),
  beforeMount () {
    this.getQuizByID()
  },
  methods: {
    getQuizByID () {
      this.$http
        .get('/quizes/?id[]=' + this.$route.params.id, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (!data[0]) {
            this.$router.push('/404')
          } else {
            this.quiz = data[0]
            // this.quiz.params = JSON.parse(data[0].params)
          }
        })
    },
    quizUpdate () {
      // todo improve realization
      const formData = new FormData()
      if (this.quiz.header_new_image) {
        formData.append('header_image', this.quiz.header_new_image)
      }
      if (this.quiz.conclude_new_image) {
        formData.append('conclude_image', this.quiz.conclude_new_image)
      }
      formData.append('id', this.quiz.id)
      formData.append('title', this.quiz.title)
      formData.append('status', this.quiz.status ? 1 : 0)
      formData.append('params', JSON.stringify(this.quiz.params))
      // intro
      formData.append('intro', this.quiz.intro)
      formData.append('header_title', this.quiz.header_title)
      // conclude
      formData.append('conclude', this.quiz.conclude)
      formData.append('conclude_title', this.quiz.conclude_title)
      formData.append('end_footer', this.quiz.end_footer)
      // system params for tango card
      formData.append('reward_auto', this.quiz.reward_auto ? 1 : 0)
      if (this.quiz.reward_subject !== null) {
        formData.append('reward_subject', this.quiz.reward_subject)
      }
      if (this.quiz.reward_message !== null) {
        formData.append('reward_message', this.quiz.reward_message)
      }
      if (this.quiz.reward_UTID !== null) {
        formData.append('reward_UTID', this.quiz.reward_UTID)
      }
      if (this.quiz.reward_ETID !== null) {
        formData.append('reward_ETID', this.quiz.reward_ETID)
      }
      this.loading = true
      this.$http
        .post('/quizes/' + this.$route.params.id, formData, { headers: authHeader() })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.quiz' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    }
  }
}
</script>
